import { Component, AfterViewInit, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, DateAdapter } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { ProcessSettingsListService } from '../../process-settings-list/process-settings-list.service';
import { MatSnackBar } from '@angular/material';
import * as _ from 'lodash';
import { SelectFormField, SelectionType } from './select-form-field.model';
import { InputDialogComponent } from 'app/main/content/global-component/input-dialog/input-dialog.component';
import { MessageSettingsComponent } from '../../../../global-component/message-settings/message-settings.component';
import { HelpersService } from '../../../../services/helpers/helpers.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TaskDescriptions } from './taskDescriptions.model';
import { IRegister } from 'app/main/content/interfaces/register.interface';
import { TranslateService } from '@ngx-translate/core';
import { InstanceFieldConst } from './instanceFields.const';

@Component({
    selector: 'app-add-task.component',
    templateUrl: './add-task.component.html',
    styleUrls: ['./add-task.component.scss']
  })
  export class AddTaskComponent implements OnInit, AfterViewInit {

    stageExc = 'init';
    docTypes = [
        {
            title: 'One Record Document',
            key: 'oneRecordDocument'
        },
        {
            title: 'Instance Document',
            key: 'instanceDocument'
        }
    ];
    formId: string;
    FormField;
    activityList;
    statusActivityList;
    selectedTaskType;
    formType: string;
    selectedIndex = 0;
    query;
    positionProcessAct;
    loading;
    taskDescriptions;
    selectFormField = {};
    task: any = {
        id: new Date().getTime(),
        name: 'Task Name',
        taskType: '',
        formType: 'main-form'
    };
    selectionType;
    processList;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    inputDialogRef: MatDialogRef<InputDialogComponent>;

    activityData: object;
    processData: object;
    selectedOffice: object;
    messageTypes: Array<any>;
    transitions = [];
    registriesListArr: Array<any>;
    triggers = [];
    waitingTransitionActivity = [];
    messageTypeShowEmail = ['gdpr-persona-approvalRequest', 'gdpr-persona-infoProcessing', 'calendar-create-event', 'process-start-another-process'];
    excecutionStages = ['init', 'pending', 'leaving', 'error'];
    taskTypes = [];
    actionONTaskFormField = [];
    activities;
    officeId: any;
    creatorId: any;
    creatorLogin: any;
    fileName: any;
    operators = [
        {
            key: '<',
            value: 'Equal to'
        },
        {
            key: '=',
            value: 'Less Than'
        },
        {
            key: '<=',
            value: 'Less Than Equal'
        },
        {
            key: '>',
            value: 'Greater Than'
        },
        {
            key: '>=',
            value: 'Greater Than Equal'
        },
        {
            key: 'in',
            value: 'Includes'
        },
        {
            key: 'between',
            value: 'Between'
        }
    ];

    positionFields = [];

    tasksWithSettings = [
        'update_register_record', 
        'generate_record_report', 
        'send_email', 
        'cron_task', 
        'check_user_in_profile',
        'user_invitation',
        'sms_one_way',
        'generate_pdf_protocol',
        'create_new_invoice',
        'send_notification',
        'messaging_jabber',
        'doller_calc_value',
        'install_office_plugin',
        'uninstall_office_plugin',
        'create_personal_office',
        'create_user_profile',
        'invite_user_to_workspace',
        'register_rec_validation',
        'invite_user_to_office',
        'invite_instance_to_profile',
        'invitation_response_trigger',
        'jump_to_another_activity',
        'create_merge_request',
        'remove_cron_task',
        'create_branch',
        'move_position_child_records',
        'cron_task_for_actions',
        'recalculate_in_and_out_to_meet_saldo',
        'register_record_report',
        'process_start_another_process',
        'process_create_register_record',
        'calendar_create_event',
        'shared_service_provider_contract',
        'update_office_service_info',
        'create_reg_process_pack',
        'update_register_doc',
        'shared_service_doc',
        'make_request',
        'make_open_ai_request',
        'open_ai_process_docs',
        'check_bank',
        'ss_update_event',
        'shared_service_client_contract',
        'discord_message',
        'parse_email',
        'find_addressbook_client',
        'find_order_record',
        'recalculate_balance_by_contact_id',
        'convert_to_number',
        'calculate_hrs_eco_trans',
        'update_reg_rec_with_filter',
        'context_html_report',
        'limitation_html',
        'create_profile_and_add_to_office',
        'crud_email_to_workspace',
        'check_org_in_profile',
        'filter_by_mq_filter_and_update',
        'create_order_route_based_on_locality',
        'init_create_order_route_based_on_locality',
        'add_new_order_to_route_based_on_locality',
        'crud_email_to_group',
        'update_profile_path',
        'update_register_rec_math_operation',
        'update_register_by_range_check',
        'update_register_rec_path',
        'convert_address_to_lat_long',
        'trigger_cron',
        'update_office_path',
    ];

    notShowAddFields = [
        'register_rec_validation'
    ];

    officeServiceStatuses = [
        {
            key: 'inProgress',
            value: 'In Progress'
        },
        {
            key: 'active',
            value: 'Active'
        },
        {
            key: 'terminated',
            value: 'Terminated'
        },
        {
            key: 'suspended',
            value: 'Suspended'
        },
    ];

    templateList = [];
    timeDiffParams = [
        {
            key: 's',
            value: 'Seconds'
        },
        {
            key: 'm',
            value: 'Minutes'
        },
        {
            key: 'h',
            value: 'Hours'
        },
        {
            key: 'd',
            value: 'Days'
        },
        {
            key: 'M',
            value: 'Months'
        },
        {
            key: 'y',
            value: 'Years'
        }
    ];

    ckEditorConfig: any;
    instanceFields: any;

    constructor(
      public dialogRef: MatDialogRef<AddTaskComponent>,
      public dialog: MatDialog,      
      @Inject(MAT_DIALOG_DATA) public data: any,
      private http: HttpClient,
      public snackBar: MatSnackBar,
      private helperService: HelpersService,
      public processSettingList: ProcessSettingsListService,
      private translationService: TranslateService,
      private _adapter: DateAdapter<any>
      ) {
        this._adapter.setLocale(this.translationService.getDefaultLang());
    
        this.instanceFields = JSON.parse(JSON.stringify(InstanceFieldConst));
       this.selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
       this.activityData = this.data['activityData'] || {};
       this.processData = this.data['processData'] || {};
       this.activityList = (this.data['processData'] && this.data['processData']['activities']) || [];
       this.statusActivityList = this.activityList.filter((act) => (act && act.type === 'state' && act.kind === 'interactive'));
       this.formType = 'main-form';
       this.selectionType = JSON.parse(JSON.stringify(SelectionType)) || [];
       this.selectFormField = JSON.parse(JSON.stringify(SelectFormField)) || {};
        this.getAllFormFields();
        this.getTemplateList();
       this.geProcessList();
       this.taskDescriptions = JSON.parse(JSON.stringify(TaskDescriptions)) || {};
       this.getRegistriesList();
        this.messageTypes = [{
            key: 'approvalRequest',
            value: 'Approval Request'
        }, {
            key: 'infoProcessing',
            value: 'Info Processing'
        }];
        
       this.processData['transitions'].forEach((trans, index) => {
            if (trans['source'] && this.activityData && trans['source'].id === this.activityData['id']) {
                this.transitions.push(trans);
            }
        });

        this.processData['activities'].forEach((activity, index) => {
            if (activity['type'] === 'trigger') {
                this.triggers.push(activity);
            }
        });

        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
        }
    
        if (JSON.parse(localStorage.getItem('ngStorage-profile'))) {
          this.creatorId = JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId'] || '';
          this.creatorLogin = JSON.parse(localStorage.getItem('ngStorage-profile'))['email'] || '';
        }

    }

    ngAfterViewInit(): void {
       if (this.data['task']) {
           console.log("task data ", this.data.task, this.selectFormField);
           this.task = this.data['task'];

           if (this.selectFormField[this.task.taskType]) {
            this.selectFormField[this.task.taskType].forEach((ele: any) => {
                this.task[this.task.taskType] = this.task[this.task.taskType] || {};
                this.task[this.task.taskType][ele.key] = this.task[this.task.taskType][ele.key] || {};
                if (!this.task[this.task.taskType][ele.key].optionType) {
                    this.task[this.task.taskType][ele.key].value = ele.key
                    this.task[this.task.taskType][ele.key].optionType = "defaultText";
                }
            })
           }
           
       }
       this.getCustomFields();
        this.stageExc = this.data['stageExc'] || this.stageExc;
        console.log(this.task);
        if ( this.task[this.task.taskType] && this.task[this.task.taskType]['task'] && this.task[this.task.taskType]['task']['value']){
            this.actionONTaskFormField = this.selectFormField[this.task[this.task.taskType]['task']['value']] || [];
        }
// task[task.taskType]['positionRegId']
        if (this.task && this.task.taskType === 'move_position_child_records') {
            if (this.task[this.task.taskType] && this.task[this.task.taskType]['positionRegId']) {
                this.positionRegChange({ value: this.task[this.task.taskType]['positionRegId']});
            }
        }
        
      }

    ngOnInit(): void {
        this.getProcessTaskTypes();
        this.selectTask(this.data.temp);
        this.getProcessMessageTypes();
        this.getWaitingTransitionActivity();
    }

    changeTaskType(){

    }

    get rapidPageValue() {
        return JSON.stringify(this.task, null, 2);
      }
    
      set rapidPageValue(v) {
        try {
          this.task = JSON.parse(v);
        }
        catch (e) {
        }
      }

    addNewKey(str) {
        this.task[this.task.taskType]['params'].push({
            key: '',
            activitie: ''
        });
    }

    save() {
        const temp = {
            type: this.stageExc,
            operation: this.data['operation'],
            task: this.task
        };
        if (this.task.name) {
            this.dialogRef.close(temp);
        } else {
            this.snackBar.open('Please Enter Task name', 'Okay', {
                duration: 2000,
            });
        }
    }

    onChangeFormType(){
        if (this.task.formType === 'main-form' && this.processData && this.processData['integratedRegisterId']){
            this.getRegisterData(this.processData['integratedRegisterId']).then((response) => {
            }).catch((err) => {
               console.info(err);
            });
        }else if (this.task.formType === 'sub-form' && this.activityData  && this.activityData['selectedForm']) {
            this.getFormField(this.activityData['selectedForm']).then((response) => {
            }).catch((err) => {
               console.info(err);
            });
        }
    }

    getProcessMessageTypes() {
        this.helperService.getRequest(`api/get-message-types`)
        .then((data) => {
            this.messageTypes = data || [];
        }).catch(err => {
            console.info(err);
        });
    }

    getRegisterData(regId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.helperService.getRegisterData(regId)
            .then((regData) => {
                this.formId = regData['ng5FormId'];
                    this.getFormField(regData['ng5FormId'])
                    .then(data => {
                        resolve(regData);
                    }).catch(err => {
                        reject();
                    });
            }).catch(reject);
        });
    }

    getTemplateList() {
        try {
            this.helperService.getTemplateList()
                .then((templateList) => {
                    this.templateList = templateList || [];
                }).catch(err => {
                    console.info(err);
                });
        } catch (err) {
            console.info(err);
        }
    }

    geProcessList() {
        this.processSettingList.getProcess().then( data => {
            this.processList = data || [];
        }).catch((err) => {
            console.info(err);
        });
    }

    getRegistriesList(){
        this.helperService.getSmallRegisterList().then(data => {
            data = data || [];
            // remove workspace type registers
            data = data.filter((reg: IRegister) => (reg && reg.type !== 'workspace'));
            this.registriesListArr = data || [];
        }).catch((err)  => {
            console.info(err);
        });
    }

    openInstructionPopup(infoKey: string) {
        this.helperService.openInstructionPopup(infoKey, 'translation');
    }

    getAllFormFields() {
        this.getRegisterData(this.processData['integratedRegisterId']).then((response) => {
            if (this.activityData['selectedForm']) {
                return this.getFormField(this.activityData['selectedForm']);
            }
        }).catch((err) => {
           console.info(err);
        });
    }


    getFormField(formId: string): Promise<object> {
        return new Promise((resolve, reject) => {
            this.getForm(formId)
            .then(data => {
                this.FormField = this.FormField || [];
                this.FormField = this.FormField.concat(this.traverse(data));
                this.positionFields = this.FormField.filter(x => x.control_type === 'position');
                if (this.task && this.task.taskType === 'process_start_another_process') {
                    // positionFields
                    this.task[this.task.taskType]['positionFields'] = this.positionFields || [];
                }
                resolve();
            })
            .catch(err => {reject(); });
        });
    }

    traverse = function(obj) {
        const ng5AllFields = [];
        obj.forEach((tabValue) => {
          if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
            tabValue.tabContent.forEach((value) => {
              if (value.field || (value.data !== null && typeof value.data === 'string') )  {
                ng5AllFields.push(value);
              }
              if (value.children && typeof value.children === 'object') {
                value.children.forEach((childValue) => {
                  if (childValue.field || (childValue.data !== null && typeof childValue.data === 'string') ) {
                    ng5AllFields.push(childValue);
                  }
                  if (childValue && typeof childValue === 'object') {
                    childValue.forEach((nestedChildValue) => {
                      if (nestedChildValue.field || (nestedChildValue.data !== null && typeof nestedChildValue.data === 'string') ) {
                        ng5AllFields.push(nestedChildValue);
                      }
                    });
                  }

                });
              }
            });
          }
        });
        return ng5AllFields;
    };


    getForm(formId: string): Promise<object> {
        return this.helperService.getForm(formId)
        .then((data) => {
            return (data && data['ng5Form']);
        });
    }


    cancel() {
        this.dialogRef.close();
    }

    delete() {
        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
      
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
      
        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
              this.dialogRef.close({type: this.stageExc, operation: 'delete'});
            }
            this.confirmDialogRef = null;
        }, err => {
            console.info(err);
          }); 
    }

    getProcessTaskTypes(){
        this.helperService.getRequest(`api/process-tasks-types`)
        .then((data) => {
            this.taskTypes = (data && data.allTasks) || [];
            // this.taskTypes.forEach(ele => {
            //     if (ele.key === this.task.taskType) {
            //         this.selectedTaskType = ele.title;
            //     }
            // });
            
        }).catch(err => {
            console.info(err);            
        });
    }

    selectTask(temp: any = {}) {
        this.task.taskType = temp.key || temp.taskType;
        this.selectedTaskType = temp.title || temp.name;
        this.task[this.task.taskType] = this.task[this.task.taskType] || { };
        this.task[this.task.taskType]['params'] = this.task[this.task.taskType]['params'] || [];
        if (this.task.taskType === 'inc_messages_gateway') {
            this.task[this.task.taskType]['inputKey'] = 'messageType';
            this.selectedIndex = 0;
        } else if (this.task.taskType === 'cron_task' || this.task.taskType === 'send_email' || this.tasksWithSettings.includes(this.task.taskType)) {
            if (this.task.taskType === 'send_email') {
                this.task[this.task.taskType]['uploadFile'] = {};
            }
            this.defaultValues(this.task.taskType);
            this.selectedIndex = 0;
            if (this.task.taskType === 'process_start_another_process') {
                // positionFields
                this.task[this.task.taskType]['positionFields'] = this.positionFields || [];
            }
        } else if (this.task.taskType === 'send_message') {
            this.openMessageSettings();
        }
    }

    openMessageSettings() {
        const tempDialog = this.dialog.open(MessageSettingsComponent, {
            disableClose: false,
            data: { 
                selectFormField: this.selectFormField, 
                task: this.task, 
                FormField: this.FormField,
                processData: this.processData
            },
            width: '100%',
            panelClass: 'happ-form-builder-dialog'
        });
      
        tempDialog.afterClosed().subscribe(result => {
            if ( result ) {
              this.task = result || {};
            }
        }, err => {
            console.info(err);
          }); 
    }

    defaultValues(messageType) {
        if (this.selectFormField[messageType]) {
            this.selectFormField[messageType] = this.selectFormField[messageType] || [];
            this.selectFormField[messageType].forEach((formField) => {
                this.task[this.task['taskType']][formField['key']] = this.task[this.task['taskType']][formField['key']] || {};
                this.task[this.task['taskType']][formField['key']]['value'] = formField['defaultValue'] || formField['key'];
                this.task[this.task['taskType']][formField['key']]['optionType'] = formField['optionType'] || 'formField';
            });
        }
    }

    actionONTaskEvent(taskType){
        this.task[this.task['taskType']]['actionOnTask'] = this.task[this.task['taskType']]['actionOnTask'] || {};
        this.actionONTaskFormField = this.selectFormField[taskType] || [];
        this.actionONTaskFormField.forEach((formField) => {
          
            this.task[this.task['taskType']]['actionOnTask'].task_key = taskType;
            this.task[this.task['taskType']]['actionOnTask'][formField['key']] = this.task[this.task['taskType']][formField['key']] || {};
            this.task[this.task['taskType']]['actionOnTask'][formField['key']]['value'] =  this.task[this.task['taskType']]['actionOnTask'][formField['key']]['value'] || formField['key'];
            this.task[this.task['taskType']]['actionOnTask'][formField['key']]['optionType'] = this.task[this.task['taskType']]['actionOnTask'][formField['key']]['optionType'] || 'formField';
        });
    }

    addField(type: string) {
        this.inputDialogRef = this.dialog.open(InputDialogComponent, {
            disableClose: false
        });
  
        if (type === 'task') {
            this.inputDialogRef.componentInstance.inputPlaceholder = 'Key Name';
            this.inputDialogRef.componentInstance.confirmMessage = 'Key Name';    
        } else {
            this.inputDialogRef.componentInstance.inputPlaceholder = 'Value Name';
            this.inputDialogRef.componentInstance.confirmMessage = 'Value Name';    
        }
        this.inputDialogRef.componentInstance.inputValue = '';
  
        this.inputDialogRef.afterClosed().subscribe(result => {
            
            if ( result && type === 'task' ) {
                if (this.tasksWithSettings.includes(this.task.taskType)) {
                    this.selectFormField[this.task.taskType] = this.selectFormField[this.task.taskType] || [];
                    this.selectFormField[this.task.taskType].push({
                        key: result,
                        value: result,
                        type: 'custom'
                    });
                
                } else {
                    this.selectFormField[this.task[this.task.taskType]['messageType']] = this.selectFormField[this.task[this.task.taskType]['messageType']] || [];
                    this.selectFormField[this.task[this.task.taskType]['messageType']].push({
                        key: result,
                        value: result,
                        type: 'custom'
                    });
                }
                this.task[this.task['taskType']][result] = this.task[this.task['taskType']][result] || {};
                this.task[this.task['taskType']][result]['value'] = result;
                this.task[this.task['taskType']][result]['optionType'] = 'formField';
                this.task[this.task['taskType']][result]['type'] = 'custom';
            
            } else {
                this.task[this.task.taskType]['mappingValue'] = this.task[this.task.taskType]['mappingValue'] || [];
                this.task[this.task.taskType]['mappingValue'].push({range: '', operator: '', value: result});
            
            }
            this.inputDialogRef = null;
        
        });
    }

    removeField(field, type: string) {
        if (type === 'task') {
            if (this.tasksWithSettings.includes(this.task.taskType)) {
                this.selectFormField[this.task.taskType] = this.selectFormField[this.task.taskType] || [];
                const index = _.findIndex(this.selectFormField[this.task.taskType], {key: field.key, type: 'custom'});
                if (index >= 0) {
                    this.selectFormField[this.task.taskType].splice(index, 1);
                }
                
                if (this.task[this.task['taskType']][field.key]) {
                    delete this.task[this.task['taskType']][field.key];
                }
            } else {
                this.selectFormField[this.task[this.task.taskType]['messageType']] = this.selectFormField[this.task[this.task.taskType]['messageType']] || [];
                const index = _.findIndex(this.selectFormField[this.task[this.task.taskType]['messageType']], {key: field.key, type: 'custom'});
                if (index >= 0) {
                    this.selectFormField[this.task[this.task.taskType]['messageType']].splice(index, 1);
                }
                
                if (this.task[this.task['taskType']][field.key]) {
                    delete this.task[this.task['taskType']][field.key];
                }
            }
        } else {
            this.task[this.task.taskType]['mappingValue'] = this.task[this.task.taskType]['mappingValue'] || [];
            this.task[this.task.taskType]['mappingValue'].splice(field, 1);
        }
    }

    getCustomFields() {
        if (this.task && this.task['taskType'] && this.task[this.task['taskType']]) {
            if (this.tasksWithSettings.includes(this.task.taskType)) {
                this.selectFormField[this.task.taskType] = this.selectFormField[this.task.taskType] || [];
                for (const key in this.task[this.task['taskType']]) {
                    if (this.task[this.task['taskType']] && this.task[this.task['taskType']][key] && this.task[this.task['taskType']][key]['type'] === 'custom') {
                        this.selectFormField[this.task.taskType].push({
                            key: key,
                            value: key,
                            type: 'custom'
                        });
                    }
                }
            } else  {
                this.selectFormField[this.task[this.task.taskType]['messageType']] = this.selectFormField[this.task[this.task.taskType]['messageType']] || [];
                for (const key in this.task[this.task['taskType']]) {
                    if (this.task[this.task['taskType']] && this.task[this.task['taskType']][key] && this.task[this.task['taskType']][key]['type'] === 'custom') {
                        this.selectFormField[this.task[this.task.taskType]['messageType']].push({
                            key: key,
                            value: key,
                            type: 'custom'
                        });
                    }
                }
            }
        }
    }

    getWaitingTransitionActivity() {
        this.processData['transitions'].forEach((trans, index) => {
            if (trans['source'] && trans['dest'] && this.activityData && trans['source'].id === this.activityData['id'] && trans['kind'] === 'waiting') {
                this.waitingTransitionActivity.push(trans['dest']);
            }
        });
    }

      
  uploadFile(file, uploadVar) {
    // tslint:disable-next-line:max-line-length
    // https://backend.pinbox24.com/api/files/5a79b44b672b8b7cc85c3fb7/upload?creatorId=5a780841de4c552fc25aebf4&creatorLogin=suryanshsinghstudy@gmail.com&officeId=5a79b44b672b8b7cc85c3fb7&recId=5a955afe520a273f540c609c&regId=5a892752b327af07b4e99c16
        const files = file.files;
        const formData = new FormData();
        for (let i = 0; i < files.length; i++ ) {
          formData.append('uplFile', files[i], files[i].name);
        }
        this.helperService.uploadFiles('', formData)
        .then((data) => {
            if (data && data.length) {
                this.task[this.task.taskType][uploadVar] = this.task[this.task.taskType][uploadVar] || {};
                this.task[this.task.taskType][uploadVar]['value'] = (data[0] && data[0]['_id']) || '';
                this.fileName = (data[0] && data[0]['originalname']) || '';
            }
        }).catch(err => {
            console.info(err);
        });
      }

      async positionRegChange(eve) {
          try {
            this.positionProcessAct = [];
            if (eve && eve.value) {
                this.loading = true;
                const regData = await this.helperService.getRegisterData(eve.value);
                if (regData && regData.integratedProcessId) {
                  let processDoc = await this.helperService.getProcess(regData.integratedProcessId);
                  processDoc = processDoc || {};
                  this.positionProcessAct = processDoc['activities'] || [];
                  this.positionProcessAct = this.positionProcessAct.filter((act) => (act && act.type === 'state' && act.kind === 'interactive'));

                }
                this.loading = false;
            }
          } catch (err) {
              console.info(err);
          }
      }
  }

