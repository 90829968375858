<div class="happ-toolbar">
    <h3>Activity Task Settings
        <span *ngIf="task && task.taskType">
            - {{ task.taskType }}
        </span>

    </h3>
    <span fxFlex></span>
    <div>
        <button mat-raised-button class="mr-40" (click)="delete()" color="warn"> Delete </button>
        <button mat-raised-button (click)="dialogRef.close()"> Cancel </button>
        <button mat-raised-button color="warn" (click)="save()"> Save </button>
    </div>
</div>
<div fxLayout="column" class="p-28">
    <div fxLayoutAlign="space-between center" fxLayout="row wrap">
        <mat-form-field floatLabel="always" appearance="outline" floatLabel="always" fxFlex="20">
            <mat-label> Task Name </mat-label>
            <input matInput placeholder="Task Name" [(ngModel)]="task.name">
        </mat-form-field>
        <div fxLayoutAlign="start center" style="margin-top: -10px;" fxLayout="column" fxFlex="15">
            <p>Execution wait</p>
            <mat-checkbox [(ngModel)]="task.wait" value="true" color="primary">Wait</mat-checkbox>
        </div>
        <div fxFlex="15" *ngIf="task.wait">
            <div fxFlex>
                <mat-form-field floatLabel="always" appearance="outline"  floatLabel="always">
                    <mat-label> Execution Time (in seconds) </mat-label>
                    <input type="number" matInput placeholder="Time in seconds" [(ngModel)]="task.waitTime">
                </mat-form-field>
            </div>
        </div>
        <div fxFlex="50" fxLayout="row">
            <div fxFlex="30">
                Execution Stage
            </div>
            <div fxLayout="row wrap" fxFlex>
                <div>
                    <button mat-raised-button class="m4 mx-8" (click)="stageExc=stage" [color]="(stageExc === stage)? 'primary': ''" *ngFor="let stage of excecutionStages"> {{ stage | uppercase }} </button>
                </div>
            </div>
        </div>
    </div>
    <mat-tab-group class="mt-16" [(selectedIndex)]="selectedIndex" >
            <!-- <mat-tab class="p-8" label="Task Type"> 
                <div class="my-8">
                    <h3 fxFlex="80" fxLayout="column">
                        Selected Task Type: {{ selectedTaskType }}
                        <p class="warn-500-fg font-size-16">
                            Changing task type will reset settings.
                        </p>
                    </h3>
                    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <label for="search" class="mr-8">
                            <mat-icon>search</mat-icon>
                        </label>
                        <mat-form-field>
                            <input matInput placeholder="Search" [(ngModel)]="query">
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex>
                    <mat-nav-list>
                        <a style="margin: 10px 0px;" [style.color]="(task.taskType === temp.key)? 'black': 'gray'" *ngFor="let temp of taskTypes | fullTextSearch:query:'title';" (click)="selectTask(temp)" fxLayout="row" mat-list-item>
                            <p fxLayout="row" class="font-size-18">
                                <b>
                                    {{ temp.title | uppercase }}                                
                                </b>
                                <span class="primary-200-fg">
                                    {{ temp.desc }}
                                </span>
                            </p>
                        </a>
                    </mat-nav-list>
                </div>
                </mat-tab> -->
                <mat-tab class="p-8" label="Task Settings"> 
                    <div fxLayout="row" *ngIf="task && taskDescriptions && taskDescriptions[task.taskType]">
                        <p> <strong>Description:</strong> {{ taskDescriptions[task.taskType] }} </p>
                    </div>
                    <div fxFlex style="height: 70vh; padding: 10px;">
                        <div *ngIf="task.taskType=='send_message'">
                            <button mat-raised-button (click)="openMessageSettings()" >
                                Open Message Setting
                            </button>
                        </div>
                        <div *ngIf="task.taskType=='start_protocol_with_another_process'">
                            <div class="mb-8">
                                <div fxFlex="30">
                                    <h4>Select Process to Start</h4>
                                </div>
                                <div fxFlex="70">
                                    <mat-form-field>
                                        <mat-select [(ngModel)]="task[task.taskType]['processId']" placeholder="Process">
                                            <mat-option *ngFor="let process of processList" [value]="process._id">
                                                {{ process.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="mb-8">
                                <div fxFlex="30">
                                    <h4>Select Register for folder</h4>
                                </div>
                                <div fxFlex="70">
                                    <mat-form-field>
                                        <mat-select [(ngModel)]="task[task.taskType]['folderRegisterId']" placeholder="Folder Register">
                                            <mat-option *ngFor="let register of registriesListArr" [value]="register._id">
                                                {{ register.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="mb-8" *ngIf="task.taskType === 'process_start_another_process'">
                            <div fxFlex="30">
                                <h3>Select Process</h3>
                            </div>
                            <div fxFlex="70">
                                <mat-select [(ngModel)]="task[task.taskType]['childProcessId']" placeholder="Select Process">
                                    <mat-option *ngFor="let process of processList" [value]="process._id">
                                        {{ process.name }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="mb-8" *ngIf="task.taskType === 'process_create_register_record'">
                            <div fxFlex="30">
                                <h3>Select Register</h3>
                            </div>
                            <div fxFlex="70" style="margin: auto;">
                                <mat-select [(ngModel)]="task[task.taskType]['childRegisterId']" placeholder="Select Register">
                                    <mat-option *ngFor="let reg of registriesListArr" [value]="reg._id">
                                        {{ reg.name }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="mb-8" *ngIf="task.taskType === 'generate_record_report'">
                            <div fxFlex="30">
                                <h3>Select Document Type</h3>
                            </div>
                            <div fxFlex="70" style="margin: auto;">
                                <mat-select [(ngModel)]="task[task.taskType]['documentType']" placeholder="Select Document Type">
                                    <mat-option *ngFor="let reg of docTypes" [value]="reg.key">
                                        {{ reg.title }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div *ngIf="task.taskType === 'process_start_another_process' || task.taskType === 'process_create_register_record'" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                            <div fxFlex="30" fxLayout="row wrap">
                                <h4 style="margin: auto; margin-left: 2px">Copy all register record</h4>
                            </div>
                            <div fxFlex="70">
                                <div fxFlex class="mx-8">
                                    <mat-checkbox [(ngModel)]="task[task.taskType]['useFullRecord']" > {{ (task[task.taskType]['useFullRecord'])? 'Yes' : 'No' }} </mat-checkbox>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="task.taskType === 'process_start_another_process'" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                            <div fxFlex="30" fxLayout="row wrap">
                                <h4 style="margin: auto; margin-left: 2px"> Position Fields
                                    <button (click)="openInstructionPopup('info.position_fields_info')" mat-icon-button>
                                        <mat-icon>info</mat-icon>
                                    </button>
                                </h4>
                            </div>
                            <div fxFlex="70">
                                <div  *ngFor="let field of task[task.taskType]['positionFields'] ">
                                    <mat-checkbox [(ngModel)]="field['selected']" > {{ (field['selected'])? 'Yes' : 'No' }} ({{ field.title }}) </mat-checkbox>
                                </div>
                            </div>                            
                        </div>

                        <div *ngIf="task.taskType=='register_rec_validation'">
                            <div class="mb-8">
                                <div fxFlex="30">
                                    <h4>Select Register Destination Register</h4>
                                </div>
                                <div fxFlex="70">
                                    <mat-form-field>
                                        <mat-select [(ngModel)]="task[task.taskType]['destinationRegId']" placeholder="Destination Register">
                                            <mat-option *ngFor="let register of registriesListArr" [value]="register._id">
                                                {{ register.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="task.taskType==='activate_trigger'">
                            <div fxLayout="column" fxLayoutGap="10px" class="p-12">
                                <div fxLayout="row">
                                    <mat-form-field>
                                        <input matInput placeholder="Input Key" [(ngModel)]="task[task.taskType]['inputKey']">
                                    </mat-form-field>
                                    <div>
                                        <button mat-raised-button color="warn" *ngIf="task[task.taskType]['params'].length === 0" class="ml-12" (click)="addNewKey('params')"> Add  </button>
                                    </div>
                                </div>
                                <div fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
                                    <div fxLayoutAlign="space-around center" class="w-48-p" fxLayout="row" *ngFor="let param of task[task.taskType]['params']; let i=index">
                                        <mat-form-field fxFlex="32" class="mr-8">
                                            <input matInput placeholder="Key" [(ngModel)]="param['key']">
                                        </mat-form-field>
                                        <mat-form-field fxFlex="32" class="mr-8">
                                            <mat-select [(ngModel)]="param['activitie']" placeholder="Trigeer">
                                                <mat-option *ngFor="let trans of transitions" [value]="trans.dest.id">
                                                    {{ trans.dest.label }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="32" class="mr-8">
                                            <input matInput placeholder="Comment" [(ngModel)]="param['comment']">
                                        </mat-form-field>
                                        <div fxLayoutAlign="center center">
                                            <button mat-raised-button class="ml-12" (click)="task[task.taskType]['params'].splice(i, 1)"> Remove </button>
                                            <button mat-raised-button color="warn" *ngIf="i === (task[task.taskType]['params'].length - 1)" class="ml-12" (click)="addNewKey('params')"> Add </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="task.taskType==='jump_to_another_activity'">
                            <div fxLayout="column" fxLayoutGap="10px" class="p-12">
                                <div fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
                                    <div fxLayoutGap="10px;" class="w-48-p" fxLayout="row" *ngFor="let param of task[task.taskType]['params']; let i=index">
                                        <mat-form-field>
                                            <input matInput placeholder="Key" [(ngModel)]="param['key']">
                                        </mat-form-field>
                                        <mat-form-field>
                                            <mat-select [(ngModel)]="param['activitie']" placeholder="Trigeer">
                                                <mat-option *ngFor="let trans of transitions" [value]="trans.dest.id">
                                                    {{ trans.dest.label }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div fxLayoutAlign="center center">
                                            <button mat-raised-button class="ml-12" (click)="task[task.taskType]['params'].splice(i, 1)"> Remove </button>
                                            <button mat-raised-button color="warn" *ngIf="i === (task[task.taskType]['params'].length - 1)" class="ml-12" (click)="addNewKey('params')"> Add </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="task.taskType==='inc_messages_gateway'">
                            <div fxLayout="column" fxLayoutGap="10px" class="p-12">
                                <div fxLayout="row">
                                    <mat-form-field>
                                        <input matInput placeholder="Input Key" [(ngModel)]="task[task.taskType]['inputKey']">
                                    </mat-form-field>
                                    <div>
                                        <button mat-raised-button color="warn" *ngIf="task[task.taskType]['params'].length === 0" class="ml-12" (click)="addNewKey('params')"> Add  </button>
                                    </div>
                                </div>
                                <div fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
                                    <div fxLayoutGap="10px;" class="w-48-p" fxLayout="row" *ngFor="let param of task[task.taskType]['params']; let i=index">
                                        <mat-form-field>
                                            <input matInput placeholder="Key" [(ngModel)]="param['key']">
                                        </mat-form-field>
                                        <mat-form-field>
                                            <mat-select [(ngModel)]="param['activitie']" placeholder="Trigeer">
                                                <mat-option *ngFor="let trigger of triggers" [value]="trigger.id">
                                                    {{ trigger.label }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div fxLayoutAlign="center center">
                                            <button mat-raised-button class="ml-12" (click)="task[task.taskType]['params'].splice(i, 1)"> Remove </button>
                                            <button mat-raised-button color="warn" *ngIf="i === (task[task.taskType]['params'].length - 1)" class="ml-12" (click)="addNewKey('params')"> Add </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="task.taskType==='inc_messages_gateway_update'">
                            <div class="mat-elevation-z1 p-4">
                                <h3>Select Fields</h3>
                                <br>
                                <div *ngFor="let formField of selectFormField[task[task.taskType]['messageType']]" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1);" class="mb-8 p-8 pb-0">
                                    <div fxFlex="30" fxLayout="row wrap">
                                            <button *ngIf="formField.type=='custom'" (click)="removeField(formField)" mat-icon-button>
                                                <mat-icon style="color: red" >delete</mat-icon>
                                            </button>
                                        <h4 style="margin: auto; margin-left: 2px">{{ formField.value }}</h4>
                                    </div>
                                    <div fxFlex="70">
                                        <mat-form-field fxFlex class="mx-8">
                                            <input matInput [(ngModel)]="task[task.taskType][formField.key]['value']" placeholder="Message Data Field">
                                        </mat-form-field>
                                        <mat-form-field fxFlex class="mx-8">
                                            <input matInput [(ngModel)]="task[task.taskType][formField.key]['key']" placeholder="Key for save in Register">
                                        </mat-form-field>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>
                                <div>
                                    <hr>
                                    <button mat-raised-button color="warn" (click)="addField()" > Add fields </button>
                                    <p>(key name once added can't be changed, only deleted)</p>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="task.taskType==='send_reply'">
                            <div fxLayout="column" fxLayoutGap="10px" class="p-12">
                                <mat-form-field>
                                    <input matInput placeholder="Input key Name" [(ngModel)]="task[task.taskType]['key']">
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput placeholder="Input key value" [(ngModel)]="task[task.taskType]['value']">
                                </mat-form-field>
                            </div>
                        </div>

                        <div *ngIf="task.taskType=='create_new_office'">
                            <div fxLayout="column" fxLayoutGap="10px" class="p-12">
                                <mat-form-field>
                                    <input matInput placeholder="Client Email" [(ngModel)]="task[task.taskType]['clientEmail']">
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput placeholder="office Template Id" [(ngModel)]="task[task.taskType]['officeTemplateId']">
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput placeholder="office Name" [(ngModel)]="task[task.taskType]['officeName']">
                                </mat-form-field>
                            </div>
                        </div>

                        <div *ngIf="task.taskType=='cron_task_for_actions'">
                            <div class="mb-8">
                                <div fxFlex="30">
                                    <h4>Start Time (base time)</h4>
                                </div>
                                <div fxFlex="70">
                                    <mat-form-field fxFlex>
                                        <mat-select [(ngModel)]="task[task.taskType]['baseTimeParam']" placeholder="Base Time">
                                            <mat-option value="now">
                                                Now
                                            </mat-option>
                                            <mat-option value="date">
                                                Date
                                            </mat-option>
                                            <mat-option value="formField">
                                                Form Field
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex *ngIf="task[task.taskType]['baseTimeParam'] === 'formField'">
                                        <mat-select [(ngModel)]="task[task.taskType]['baseTimeVal']" placeholder="File Field">
                                            <mat-option *ngFor="let field of FormField" [value]="field.label">
                                                {{ field.title }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex *ngIf="task[task.taskType]['baseTimeParam'] === 'date'">
                                        <input [(ngModel)]="task[task.taskType]['baseTimeVal']" matInput [matDatepicker]="picker" placeholder="Choose a date">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="mb-8">
                                <div fxFlex="30">
                                    <h4>Time Difference Param</h4>
                                </div>
                                <div fxFlex="70">
                                    <mat-form-field fxFlex>
                                        <mat-select [(ngModel)]="task[task.taskType]['timeDiffParam']" placeholder="Time Difference Param">
                                            <mat-option *ngFor="let td of timeDiffParams" [value]="td.key">
                                                {{ td.value }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex class="mx-8">
                                        <input matInput type="number" [(ngModel)]="task[task.taskType]['defaultTimeDiff']" placeholder="Default Time Difference">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div *ngFor="let taskWithSetting of tasksWithSettings">
                            <div class="mb-12" *ngIf="task.taskType==taskWithSetting">

                                <!-- extra settings -->

                                <div *ngIf="task.taskType=='update_register_record'" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1);" class="mb-8 p-8 pb-0">
                                    <div fxFlex="30" fxLayout="row wrap">
                                        <h4 style="margin: auto; margin-left: 2px">Register Record Field</h4>
                                    </div>
                                    <div fxFlex="70">
                                        <mat-form-field fxFlex class="mx-8">
                                            <mat-select [(ngModel)]="task[task.taskType]['updateRecordId']" placeholder="Select type">
                                                <mat-option value="instanceRecId">
                                                    Instance Record Id
                                                </mat-option>
                                                <mat-option value="recordField">
                                                    Record Field
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field fxFlex class="mx-8" *ngIf="task[task.taskType]['updateRecordId'] === 'recordField'">
                                            <input matInput [(ngModel)]="task[task.taskType]['recordField']" placeholder="Record Field">
                                        </mat-form-field>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>

                                <div *ngIf="task.taskType==='user_invitation'" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                                    <div fxFlex="30" fxLayout="row wrap">
                                        <h4 style="margin: auto; margin-left: 2px"> Select Activity</h4>
                                    </div>
                                    <div fxFlex="70">
                                        <div fxLayout="column" fxLayoutGap="10px" class="p-12">
                                            <mat-form-field>
                                                <mat-select [(ngModel)]="task[task.taskType]['activityId']" placeholder="Triger">
                                                    <mat-option *ngFor="let trigger of triggers" [value]="trigger.id">
                                                        {{ trigger.label }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>
 
                                <div *ngIf="task.taskType=='send_email'" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                                    <div fxFlex="30" fxLayout="row wrap">
                                        <h4 style="margin: auto; margin-left: 2px">Upload file</h4>
                                    </div>
                                    <div fxFlex="70" *ngIf="task[task.taskType]['uploadFile']" style="align-items: center;" fxLayout="row" >
                                        <mat-form-field fxFlex class="mx-8">
                                            <mat-select [(ngModel)]="task[task.taskType]['uploadFile']['optionType']" placeholder="Select type">
                                                <mat-option value="uploadFile">
                                                    Upload File
                                                </mat-option>
                                                <mat-option value="formField">
                                                    Form Field
                                                </mat-option>
                                                <mat-option value="allRecordFiles">
                                                    All Uploaded Files in Record
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div fxFlex fxLayout="row" *ngIf="task[task.taskType]['uploadFile']['optionType'] === 'uploadFile'">
                                            <div class="file-upload">
                                                <label for="upload" class="file-upload__label">Upload file</label>
                                                <input id="upload" (change)="uploadFile(file, 'uploadFile')" class="file-upload__input" name="uplFile" #file type="file">
                                            </div>
                                            <p class="ml-8">
                                                Uploaded: {{ fileName || task[task.taskType]['uploadFile']['value'] }}
                                            </p>
                                        </div>
                                        <div fxFlex *ngIf="task[task.taskType]['uploadFile']['optionType'] === 'formField'">
                                            <mat-select [(ngModel)]="task[task.taskType]['uploadFile']['value']" placeholder="File Field">
                                                <mat-option *ngFor="let field of FormField" [value]="field.label">
                                                    {{ field.title }}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="task.taskType=='cron_task'" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                                    <div fxFlex="30" fxLayout="row wrap">
                                        <h4 style="margin: auto; margin-left: 2px">Select the Activity</h4>
                                    </div>
                                    <div fxFlex="70">
                                        <mat-form-field fxFlex class="mx-8">
                                            <mat-select [(ngModel)]="task[task.taskType]['waitingActivityId']" placeholder="Waiting Activity">
                                                <mat-option *ngFor="let activity of waitingTransitionActivity" [value]="activity.id">
                                                    {{ activity.label }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <ng-container *ngIf="task.taskType=='make_request'">
                                    <div style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                                        <div fxFlex="30" fxLayout="row wrap">
                                            <h4 style="margin: auto; margin-left: 2px">Request Type</h4>
                                        </div>
                                        <div fxFlex="70">
                                            <mat-form-field fxFlex class="mx-8">
                                                <mat-select [(ngModel)]="task[task.taskType]['type']" placeholder="Request Type">
                                                    <mat-option [value]="'get'">
                                                        GET
                                                    </mat-option>
                                                    <mat-option [value]="'put'">
                                                        PUT
                                                    </mat-option>
                                                    <mat-option [value]="'post'">
                                                        POST
                                                    </mat-option>
                                                    <mat-option [value]="'delete'">
                                                        DELETE
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                                        <div fxFlex="30" fxLayout="row wrap">
                                            <h4 style="margin: auto; margin-left: 2px">Request Url</h4>
                                        </div>
                                        <div fxFlex="70">
                                            <mat-form-field fxFlex class="mx-8">
                                                <input matInput [(ngModel)]="task[task.taskType]['url']" placeholder="Request Url">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                                        <div fxFlex="30" fxLayout="row wrap">
                                            <h4 style="margin: auto; margin-left: 2px">Request Headers (It should be valid json) [Optional] </h4>
                                        </div>
                                        <div fxFlex="70">
                                            <mat-form-field fxFlex class="mx-8 w-100-p">
                                                <textarea  matInput [(ngModel)]="task[task.taskType]['header']" placeholder="Request Headers">{{ task[task.taskType]['header'] }}</textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                                        <div fxFlex="30" fxLayout="row wrap">
                                            <h4 style="margin: auto; margin-left: 2px">Request Body (It should be valid json) [Optional]</h4>
                                        </div>
                                        <div fxFlex="70">
                                            <mat-form-field fxFlex class="mx-8 w-100-p">
                                                <textarea  matInput [(ngModel)]="task[task.taskType]['body']" placeholder="Request Body">{{ task[task.taskType]['body'] }}</textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </ng-container>

                                <div *ngIf="task.taskType=='generate_pdf_protocol' || task.taskType=='create_new_invoice' || task.taskType=='discord_message'" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                                    <div fxFlex="30" fxLayout="row wrap">
                                        <h4 style="margin: auto; margin-left: 2px">Select Template</h4>
                                    </div>
                                    <div fxFlex="70">
                                        <mat-form-field fxFlex class="mx-8">
                                            <mat-select [(ngModel)]="task[task.taskType]['templateId']" placeholder="Select Template">
                                                <mat-option *ngFor="let template of templateList" [value]="template._id">
                                                    {{ template.title }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div *ngIf="task.taskType=='doller_calc_value'" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                                    <div fxFlex="30" fxLayout="row wrap">
                                        <h4 style="margin: auto; margin-left: 2px">Map the calculated Value</h4>
                                    </div>
                                    <div fxFlex="70">
                                        <div class="mx-8">
                                            <mat-checkbox [(ngModel)]="task[task.taskType]['mapValue']" (click)="task[task.taskType]['mappingValue']=[{range: '', operator: '', value: ''}]" >Map calculated value</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="column" *ngIf="task.taskType=='move_position_child_records'">
                                    <div  style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8">
                                        <div fxFlex="30" fxLayout="row wrap">
                                            <h4 style="margin: auto; margin-left: 2px">Position To Use</h4>
                                        </div>
                                        <div fxFlex="70">
                                            <div class="mx-8">
                                                <mat-form-field fxFlex class="mx-8">
                                                    <mat-select (selectionChange)="positionRegChange($event)" [(ngModel)]="task[task.taskType]['positionRegId']" placeholder="Select Position">
                                                        <mat-option *ngFor="let positionField of positionFields" [value]="positionField.postionsLookupSettings.regId">
                                                            {{ positionField.title }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <mat-spinner *ngIf="loading"></mat-spinner>
                                    <div  style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8" *ngIf="!loading">
                                        <div fxFlex="30" fxLayout="row wrap">
                                            <h4 style="margin: auto; margin-left: 2px">From Activity</h4>
                                        </div>
                                        <div fxFlex="70">
                                            <div class="mx-8">
                                                <mat-form-field fxFlex class="mx-8">
                                                    <mat-select [(ngModel)]="task[task.taskType]['fromActivity']" placeholder="Select Activity">
                                                        <mat-option *ngFor="let act of positionProcessAct" [value]="act.id">
                                                            {{ act.label }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div  style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1); height: 75px;" class="mb-8 p-8" *ngIf="!loading">
                                        <div fxFlex="30" fxLayout="row wrap">
                                            <h4 style="margin: auto; margin-left: 2px">To Activity</h4>
                                        </div>
                                        <div fxFlex="70">
                                            <div class="mx-8">
                                                <mat-form-field fxFlex class="mx-8">
                                                    <mat-select [(ngModel)]="task[task.taskType]['toActivity']" placeholder="Select Activity">
                                                        <mat-option *ngFor="let act of positionProcessAct" [value]="act.id">
                                                            {{ act.label }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- extra setting ends -->

                                <div *ngFor="let formField of selectFormField[taskWithSetting]" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1);" class="mb-8 p-8 pb-0">
                                    <ng-container>
                                        <!-- {{ task[task.taskType][formField.key] | json }} -->
                                    <!-- <ng-container *ngIf="!formField.filter || (formField.filter | mqFilter:row.condition:'string')"> -->
                                        <div fxFlex="30" fxLayout="row wrap">
                                            <button *ngIf="formField.type=='custom'" (click)="removeField(formField, 'task')" mat-icon-button>
                                                <mat-icon style="color: red" >delete</mat-icon>
                                            </button>
                                            <h4 fxLayout="column" style="margin: auto; margin-left: 2px">
                                                {{ formField.value }}
                                                <p class="m-0 mt-4" style="font-size: 12px;">
                                                    {{ formField.description }}
                                                    {{ ('process_task.' + task.taskType + '_' + formField.key) | translate }}
                                                    <!-- {{ formField.description }} -->
                                                </p>
                                            </h4>
                                        </div>
                                        <div fxFlex="70">
                                            <mat-form-field fxFlex class="mx-8 happ-half-width">
                                                <mat-select (selectionChange)="task[task.taskType][formField.key]['value']=''" [(ngModel)]="task[task.taskType][formField.key]['optionType']" placeholder="Select type" >
                                                    <mat-option *ngFor="let selType of selectionType" [value]="selType.key">
                                                        {{ selType.value }}
                                                    </mat-option>
                                                    <mat-option *ngIf="formField.key === 'end'" value="fromStart">
                                                        From Start
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field fxFlex class="mx-8" *ngIf="task[task.taskType][formField.key]['optionType'] === 'officeServiceStatus'">
                                                <mat-select [(ngModel)]="task[task.taskType][formField.key]['value']" [placeholder]="formField.value">
                                                    <mat-option *ngFor="let field of officeServiceStatuses" [value]="field.key">
                                                        {{ field.value }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field fxFlex class="mx-8" *ngIf="task[task.taskType][formField.key]['optionType'] === 'formField'">
                                                <input [(ngModel)]="task[task.taskType][formField.key]['value']" matInput [placeholder]="formField.value">
                                            </mat-form-field>
                                            <mat-form-field fxFlex class="mx-8" *ngIf="task[task.taskType][formField.key]['optionType'] === 'task'">
                                                <mat-select [(ngModel)]="task[task.taskType][formField.key]['value']" (ngModelChange)="actionONTaskEvent(task[task.taskType][formField.key]['value'])" placeholder="Select Task">
                                                    <mat-option *ngFor="let task of taskTypes" [value]="task.key">
                                                        {{ task.title }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div fxFlex fxLayout="row" *ngIf="task[task.taskType][formField.key]['optionType'] === 'activity'">
                                                <mat-form-field fxFlex class="mx-8" >
                                                    <mat-select [(ngModel)]="task[task.taskType][formField.key]['value']" placeholder="Select Activity">
                                                        <mat-option *ngFor="let activity of statusActivityList" [value]="activity.id">
                                                            {{ activity.label }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex class="mx-8" style="display: flex" *ngIf="task[task.taskType][formField.key]['optionType'] === 'defaultDate'">
                                                <mat-form-field>
                                                    <input [(ngModel)]="task[task.taskType][formField.key]['value']" matInput [matDatepicker]="picker" placeholder="Choose a date">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex class="mx-8" style="display: flex" *ngIf="task[task.taskType][formField.key]['optionType'] === 'position'">
                                                <mat-form-field fxFlex class="mx-8">
                                                    <mat-select [(ngModel)]="task[task.taskType][formField.key]['value']" placeholder="Select Position">
                                                        <mat-option *ngFor="let positionField of positionFields" [value]="positionField.postionsLookupSettings.regId">
                                                            {{ positionField.title }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex class="mx-8" style="display: flex" *ngIf="task[task.taskType][formField.key]['optionType'] === 'select'">
                                                <mat-form-field fxFlex class="mx-8">
                                                    <mat-select [(ngModel)]="task[task.taskType][formField.key]['value']" placeholder="Select">
                                                        <mat-option *ngFor="let option of formField.options" [value]="option.value">
                                                            {{ option.title }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <mat-form-field fxFlex class="mx-8" *ngIf="task[task.taskType][formField.key]['optionType'] === 'instanceRecordField'">
                                                <mat-select [(ngModel)]="task[task.taskType][formField.key]['value']" placeholder="Instance record field">
                                                    <mat-option *ngFor="let instanceField of instanceFields" [value]="instanceField.keyName">
                                                        {{ instanceField.title }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field fxFlex *ngIf="task[task.taskType][formField.key]['optionType'] === 'now'">
                                                <input matInput [(ngModel)]="task[task.taskType][formField.key]['dateFormat']" placeholder="Default Time Format[Optional]">
                                            </mat-form-field>
                                            <div fxFlex class="mx-8" *ngIf="task[task.taskType][formField.key]['optionType'] === 'defaultText'">
                                                <mat-form-field fxFlex *ngIf="formField.key === 'templateId'">
                                                    <mat-select [(ngModel)]="task[task.taskType][formField.key]['value']" placeholder="Select Template">
                                                        <mat-option *ngFor="let template of templateList" [value]="template._id">
                                                            {{ template.title }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field class="w-100-p" *ngIf="formField.key !== 'templateId'">
                                                    <input matInput [(ngModel)]="task[task.taskType][formField.key]['value']" placeholder="Default Value">
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex class="mx-8" *ngIf="task[task.taskType][formField.key]['optionType'] === 'defaultEditor'">
                                                <app-html-editor type="admin" [(model)]="task[task.taskType][formField.key]['value']"></app-html-editor>
                                            </div>
                                            <mat-form-field fxFlex class="mx-8 w-100-p" *ngIf="task[task.taskType][formField.key]['optionType'] === 'defaultTextArea'">
                                                <textarea  matInput [(ngModel)]="task[task.taskType][formField.key]['value']" placeholder="Default Value">{{ task[task.taskType][formField.key]['value'] }}</textarea>
                                            </mat-form-field>
                                            <div class="mx-8 w-100-p happ-margin-auto" *ngIf="task[task.taskType][formField.key]['optionType'] === 'checkbox'">
                                                <mat-checkbox [(ngModel)]="task[task.taskType][formField.key]['value']"></mat-checkbox>
                                            </div>
                                            <mat-form-field fxFlex class="mx-8" *ngIf="formField.key === 'start'">
                                                <input matInput [(ngModel)]="task[task.taskType][formField.key]['Add']" placeholder="10m 2h 3d">
                                            </mat-form-field>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </ng-container>
                                </div>
                                
                                 <!-- action on task -->
                                <div *ngIf="actionONTaskFormField.length">
                                    {{task[task.taskType]['actionOnTask'].task_key}} settings
                                    <div *ngFor="let formField of actionONTaskFormField" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1);" class="mb-8 p-8 pb-0">
                                        
                                        <div fxFlex="30" fxLayout="row wrap">
                                            <h4 style="margin: auto; margin-left: 2px">{{ formField.value }}</h4>
                                            <p class="m-0 mt-0" style="font-size: 12px;">
                                                {{ formField.description }}
                                            </p>
                                        </div>

                                        <div fxFlex="70">
                                            <mat-form-field fxFlex class="mx-8" style="width: 50%; min-width: 48%;" >
                                                <mat-select (selectionChange)="task[task.taskType]['actionOnTask'][formField.key]['value']=''" [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['optionType']" placeholder="Select type" >
                                                    <mat-option *ngFor="let selType of selectionType" [value]="selType.key">
                                                        {{ selType.value }}
                                                    </mat-option>
                                                    <mat-option *ngIf="formField.key === 'end'" value="fromStart">
                                                        From Start
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field fxFlex class="mx-8" *ngIf="task[task.taskType]['actionOnTask'][formField.key]['optionType'] === 'formField'">
                                                <mat-select [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['value']" [placeholder]="formField.value">
                                                    <mat-option *ngFor="let field of FormField" [value]="field.label">
                                                        {{ field.title }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field fxFlex class="mx-8" *ngIf="task[task.taskType]['actionOnTask'][formField.key]['optionType'] === 'task'">
                                                <mat-select [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['value']" (ngModelChange)="actionONTaskEvent(task[task.taskType]['actionOnTask'][formField.key]['value'])" placeholder="Select Activity">
                                                    <mat-option *ngFor="let task of taskTypes" [value]="task.key">
                                                        {{ task.title }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field fxFlex class="mx-8" *ngIf="task[task.taskType]['actionOnTask'][formField.key]['optionType'] === 'activity'">
                                                <mat-select [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['value']" placeholder="Select Activity">
                                                    <mat-option *ngFor="let activity of activityList" [value]="activity.id">
                                                        {{ activity.label }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field fxFlex class="mx-8" *ngIf="task[task.taskType]['actionOnTask'][formField.key]['optionType'] === 'position'">
                                                <mat-select [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['value']" placeholder="Select Position">
                                                    <mat-option *ngFor="let positionField of positionFields" [value]="positionField.postionsLookupSettings.regId">
                                                        {{ positionField.title }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div fxFlex class="mx-8" style="display: flex" *ngIf="task[task.taskType]['actionOnTask'][formField.key]['optionType'] === 'defaultDate'">
                                                <mat-form-field>
                                                    <input [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['value']" matInput [matDatepicker]="picker" placeholder="Choose a date">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            <mat-form-field fxFlex class="mx-8" *ngIf="task[task.taskType]['actionOnTask'][formField.key]['optionType'] === 'instanceRecordField'">
                                                <!-- <input matInput [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['value']" placeholder="Instance record field"> -->
                                                <mat-select [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['value']" placeholder="Instance record field">
                                                    <mat-option *ngFor="let instanceField of instanceFields" [value]="instanceField.keyName">
                                                        {{ instanceField.title }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div fxFlex class="mx-8" *ngIf="task[task.taskType]['actionOnTask'][formField.key]['optionType'] === 'defaultText'">
                                                <mat-form-field fxFlex *ngIf="formField.key === 'templateId'">
                                                    <mat-select [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['value']" placeholder="Select Template">
                                                        <mat-option *ngFor="let template of templateList" [value]="template._id">
                                                            {{ template.title }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="formField.key !== 'templateId'">
                                                    <input matInput [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['value']" placeholder="Default Value">
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex class="mx-8" *ngIf="task[task.taskType]['actionOnTask'][formField.key]['optionType'] === 'defaultEditor'">
                                                <app-html-editor type="admin" [(model)]="task[task.taskType]['actionOnTask'][formField.key]['value']"></app-html-editor>
                                            </div>
                                            <mat-form-field fxFlex class="mx-8 w-100-p" *ngIf="task[task.taskType]['actionOnTask'][formField.key]['optionType'] === 'defaultTextArea'">
                                                <textarea  matInput [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['value']" placeholder="Default Value">{{ task[task.taskType][formField.key]['value'] }}</textarea>
                                            </mat-form-field>
                                            <div class="mx-8 w-100-p happ-margin-auto"  *ngIf="task[task.taskType]['actionOnTask'][formField.key]['optionType'] === 'checkbox'">
                                                <mat-checkbox [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['value']"></mat-checkbox>
                                            </div>
                                            <mat-form-field fxFlex class="mx-8" *ngIf="formField.key === 'start'">
                                                <input matInput [(ngModel)]="task[task.taskType]['actionOnTask'][formField.key]['Add']" placeholder="-10m -2d 10m 2h 3d">
                                            </mat-form-field>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </div>  
                                </div>
                                <!-- action -->


                                <div *ngIf="!notShowAddFields.includes(task.taskType)">
                                    <hr>
                                    <button mat-raised-button color="warn" (click)="addField('task')" > Add fields </button>
                                    <p>(key name once added can't be changed, only deleted)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab class="p-8" label="Task Json">
                    <div style="min-height: 65vh;">
                        <h2>Json View</h2>
                        <textarea rows="15" name="" style="height: 80%; width: 100%;"
                        id="" [(ngModel)]='rapidPageValue'></textarea>
                    </div>
                </mat-tab>
            <mat-tab *ngIf="task[task.taskType] && task[task.taskType]['mapValue'] && task.taskType=='doller_calc_value'" class="p-8" label="Mapping"> 
                <div *ngFor="let formField of task[task.taskType]['mappingValue']; let i=index" style="border: 1px solid rgba(200,200,200,0.5); background: rgba(200,200,200,0.1);" class="mb-8 p-8 pb-0">
                    <div fxFlex="30" fxLayout="row wrap">
                        <button *ngIf="formField.type=='custom'" (click)="removeField(i, 'range')" mat-icon-button>
                            <mat-icon style="color: red" >delete</mat-icon>
                        </button>
                        <mat-form-field fxFlex class="mx-8">
                            <input matInput [(ngModel)]="formField.value" placeholder="Value">
                        </mat-form-field>
                    </div>
                    <div fxFlex="70">
                        <mat-form-field fxFlex class="mx-8">
                            <mat-select [(ngModel)]="task[task.taskType]['mappingValue'][i]['operator']" placeholder="Select operator">
                                <mat-option *ngFor="let oprator of operators" [value]="oprator.key">
                                    {{ oprator.value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex class="mx-8" >
                            <input matInput [(ngModel)]="task[task.taskType]['mappingValue'][i]['range']" placeholder="range value">
                        </mat-form-field>
                    </div>
                    <mat-divider></mat-divider>
                </div>
                <div>
                    <hr>
                    <button mat-raised-button color="warn" (click)="addField('range')" > Add fields </button>
                    <p>(key name once added can't be changed, only deleted)</p>
                </div>
            </mat-tab>
        </mat-tab-group>
</div>